@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Thin.eot');
    src: local('Recoleta Alt Thin'), local('RecoletaAlt-Thin'),
        url('RecoletaAlt-Thin.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Thin.woff2') format('woff2'),
        url('RecoletaAlt-Thin.woff') format('woff'),
        url('RecoletaAlt-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Medium.eot');
    src: local('Recoleta Medium'), local('Recoleta-Medium'),
        url('Recoleta-Medium.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Medium.woff2') format('woff2'),
        url('Recoleta-Medium.woff') format('woff'),
        url('Recoleta-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-SemiBold.eot');
    src: local('Recoleta Alt SemiBold'), local('RecoletaAlt-SemiBold'),
        url('RecoletaAlt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-SemiBold.woff2') format('woff2'),
        url('RecoletaAlt-SemiBold.woff') format('woff'),
        url('RecoletaAlt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Medium.eot');
    src: local('Recoleta Alt Medium'), local('RecoletaAlt-Medium'),
        url('RecoletaAlt-Medium.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Medium.woff2') format('woff2'),
        url('RecoletaAlt-Medium.woff') format('woff'),
        url('RecoletaAlt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Regular.eot');
    src: local('Recoleta Alt Regular'), local('RecoletaAlt-Regular'),
        url('RecoletaAlt-Regular.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Regular.woff2') format('woff2'),
        url('RecoletaAlt-Regular.woff') format('woff'),
        url('RecoletaAlt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Thin.eot');
    src: local('Recoleta Thin'), local('Recoleta-Thin'),
        url('Recoleta-Thin.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Thin.woff2') format('woff2'),
        url('Recoleta-Thin.woff') format('woff'),
        url('Recoleta-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Bold.eot');
    src: local('Recoleta Alt Bold'), local('RecoletaAlt-Bold'),
        url('RecoletaAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Bold.woff2') format('woff2'),
        url('RecoletaAlt-Bold.woff') format('woff'),
        url('RecoletaAlt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Black.eot');
    src: local('Recoleta Alt Black'), local('RecoletaAlt-Black'),
        url('RecoletaAlt-Black.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Black.woff2') format('woff2'),
        url('RecoletaAlt-Black.woff') format('woff'),
        url('RecoletaAlt-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta Alt';
    src: url('RecoletaAlt-Light.eot');
    src: local('Recoleta Alt Light'), local('RecoletaAlt-Light'),
        url('RecoletaAlt-Light.eot?#iefix') format('embedded-opentype'),
        url('RecoletaAlt-Light.woff2') format('woff2'),
        url('RecoletaAlt-Light.woff') format('woff'),
        url('RecoletaAlt-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-SemiBold.eot');
    src: local('Recoleta SemiBold'), local('Recoleta-SemiBold'),
        url('Recoleta-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-SemiBold.woff2') format('woff2'),
        url('Recoleta-SemiBold.woff') format('woff'),
        url('Recoleta-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Regular.eot');
    src: local('Recoleta Regular'), local('Recoleta-Regular'),
        url('Recoleta-Regular.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Regular.woff2') format('woff2'),
        url('Recoleta-Regular.woff') format('woff'),
        url('Recoleta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Bold.eot');
    src: local('Recoleta Bold'), local('Recoleta-Bold'),
        url('Recoleta-Bold.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Bold.woff2') format('woff2'),
        url('Recoleta-Bold.woff') format('woff'),
        url('Recoleta-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Black.eot');
    src: local('Recoleta Black'), local('Recoleta-Black'),
        url('Recoleta-Black.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Black.woff2') format('woff2'),
        url('Recoleta-Black.woff') format('woff'),
        url('Recoleta-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Recoleta';
    src: url('Recoleta-Light.eot');
    src: local('Recoleta Light'), local('Recoleta-Light'),
        url('Recoleta-Light.eot?#iefix') format('embedded-opentype'),
        url('Recoleta-Light.woff2') format('woff2'),
        url('Recoleta-Light.woff') format('woff'),
        url('Recoleta-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

